export const filterProps = {
	statusTypes () {
		return [
			{
				"key"	: "new",
				"label"	: "New",
			},
			{
				"key"	: "in_processing",
				"label"	: "In processing",
			},
			{
				"key"	: "completed",
				"label"	: "Completed",
			},
			{
				"key"	: "declined",
				"label"	: "Declined",
			}
		]
	}
}

export function positionTableHead () {
	return [
		{
			text    : 'ID',
			value   : 'id'
		},
		{
			text    : 'Created at',
			value   : 'created_at'
		},
		{
			text    : 'Email',
			value   : 'email'
		},
		{
			text    : 'Name',
			value   : 'user'
		},
		{
			text    : 'Tariff id',
			value   : 'entity_id'
		},
		{
			text    : 'Status',
			value   : 'status'
		},
		{
			text    : 'Type',
			value   : 'type'
		},
		{
			text    : 'Amount',
			value   : 'amount'
		},
		{
			text    : 'Getaway',
			value   : 'getaway'
		},
		{
			text    : 'Card',
			value   : 'card'
		},
		{
			text    : 'Description',
			value   : 'description'
		},
		{
			text    : 'Actions',
			value   : 'actions',
			sortable: false
		}
	]
}
